import React from 'react';
import dynamic from 'node_modules/next/dynamic';
import styles from 'Custom/ModalPDF/index.module.scss';
import Modal from 'Nori/Modal';
import CloseIcon from 'Images/brand-icons/close-modal-nexplanon.svg';
import { useAppContext } from 'Providers/App';
import RichText from 'Custom/RichText';
import { UI_ELEMENTS } from 'Providers/App/elements';

const { modalPDF: modalPDFId } = UI_ELEMENTS;

const DEFAULT_PDF = '/pdf/n_nexplanon_nexplanon_pi_ppi_combo.pdf';
const PDF_MODAL_JOB = 'US-XPL-117380 10/24';

const PDFViewer = dynamic(() => import('Custom/PDFViewer'), {
  ssr: false,
});

const ModalPDF = ({
  className = '',
  pdf = DEFAULT_PDF,
  paginator = true,
  ...props
}) => {
  const { updateElementState, getElementState, focusTrigger } = useAppContext();

  const modalPDF = getElementState(modalPDFId);

  const handleClose = () => {
    updateElementState(modalPDFId, false);
    focusTrigger(modalPDFId);
  };

  return (
    <Modal
      isOpen={modalPDF}
      onClose={handleClose}
      modalTitle="Modal ISI PDF"
      className={`${styles.pdfModal} ${className}`}
      data-design-visible="visible_vd"
      data-design-type="module"
      data-design-nori_id="Modal"
      data-design-module_name="ISI PDF"
      data-design-module_no="00"
      {...props}
    >
      <button
        aria-label="Close Modal"
        className={styles.closeIcon}
        onClick={handleClose}
        data-design-category="button_vd"
        data-design-type="clickAction"
        data-design-action="Close"
        data-design-label="close icon"
        data-design-nori_id="Modal"
        data-design-module_name="ISI PDF"
        data-design-module_no="00"
      >
        <CloseIcon />
      </button>
      <div className={styles.modalContent}>
        <div className={styles.pdfWrapper}>
          <PDFViewer
            pdf={pdf}
            paginator={paginator}
            moduleId="Modal"
            moduleName="ISI PDF"
            moduleNo="00"
          />
        </div>
        <RichText className={styles.jobCode}>
          <p>{PDF_MODAL_JOB}</p>
        </RichText>
      </div>
    </Modal>
  );
};

export default ModalPDF;
